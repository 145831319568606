import React from 'react';
import axios from 'axios';

class NavBarLabels extends React.Component {

    render() {
        const labelProps = this.props.labels;
        const labels = labelProps.map((label) => {

            if (label.toLowerCase() === 'home') {
                return <li key={label}>
                            <a href="/" className="nav-link">
                                {label}
                            </a>
                        </li>
            } else {
                return <li key={label}>
                            <a href={ '#' + label.toLowerCase().split(' ').join('-') } className="nav-link">
                                {label}
                            </a>
                        </li>
            }
        });

        return(
            <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                {labels}
            </ul>
        );
    }   
}

class NavBar extends React.Component {

    render() {

        const labels = this.props.navBarLables;

        return(
            // Mobile Menu
            <div>
            <div className="site-mobile-menu site-navbar-target">
            <div className="site-mobile-menu-header">
                <div className="site-mobile-menu-close mt-3">
                <span className="icofont-close js-menu-toggle" />
                </div>
            </div>
            <div className="site-mobile-menu-body" />
            </div>
            {/* Desktop Labels */}
            <header className="site-navbar js-sticky-header site-navbar-target" role="banner">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-6 col-lg-2">
                    <h1 className="mb-0 site-logo">
                    <a href="/" className="mb-0">
                        <img id="logo" height="60" alt="site-logo" src={ process.env.PUBLIC_URL + '/assets/img/logo-white.svg' } />
                    </a>
                    </h1>
                </div>
                <div className="col-12 col-md-10 d-none d-lg-block">
                    <nav className="site-navigation position-relative text-right" role="navigation">
                    <NavBarLabels labels={labels} />
                    </nav>
                </div>
                <div className="col-6 d-inline-block d-lg-none ml-md-0 py-3" style={{position: 'relative', top: '3px'}}>
                <a href="/" className="burger site-menu-toggle js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
                <span />
                </a>
                </div>
                </div>
            </div>
        </header>
        </div>
        );
    }
}

class Banner extends React.Component {

    render() {
        const title = this.props.title;
        const subtitle = this.props.subtitle;

        return(
            <section className="hero-section" id="hero">
            <div className="wave">
                <svg width="100%" height="355px" viewBox="0 0 1920 355" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                    <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path" />
                    </g>
                </g>
                </svg>
            </div>
            <div className="container">
                <div className="row align-items-center">
                <div className="col-12 hero-text-image">
                    <div className="row">
                    <div className="col-lg-7 text-center text-lg-left">
                        <h1 data-aos="fade-right">{title}</h1>
                        <p className="mb-5" data-aos="fade-right" data-aos-delay="200">{subtitle}</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        );
    }   
}

class Services extends React.Component {
    
    render() {

        const servicesList = this.props.services;
        const colSize = parseInt(12 / servicesList.length);
        const colClass = "col-md-" + colSize;
        
        let animIncFactor = 0;
        if (servicesList.length <= 6) {
            animIncFactor = 100;
        } else {
            animIncFactor = 50;
        }

        const servicesElement = servicesList.map((service) => 
           
            <div key={service.name} className={colClass} data-aos="fade-up" data-aos-delay={servicesList.indexOf(service)*animIncFactor}>
            <div className="feature-1 text-center">
                <div className="wrap-icon icon-1">
                <span className={"icon la " + service.icon} />
                </div>
                <h3 className="mb-3">{service.name}</h3>
                <p className="text-black" >{service.description}</p>
            </div>
            </div>
        );

        return (
            <section className="section">
            <div className="container">
            <div className="row justify-content-center text-center mb-5">
                <div className="col-md-6" data-aos="fade-up">
                <h2 className="section-heading">Our Services</h2>
                </div>
            </div>
            <div className="row">
                {servicesElement}
            </div>
            </div></section>
        );
    }  
}

function ElementLeft(props) {
    const name = props.element.name;
    const description = props.element.description;
    const img_path = props.element.img_path;
    return(
        <section className="section">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-4 ml-auto order-2">
                <h2 className="mb-4">{name}</h2>
                <p className="mb-4">{description}</p>
                </div>
                <div className="col-md-6" data-aos="fade-right">
                <img src={ process.env.PUBLIC_URL + img_path } alt="" className="img-fluid" />
                </div>
            </div>
            </div>
        </section>
    );
}

function ElementRight(props) {
    const name = props.element.name;
    const description = props.element.description;
    const img_path = props.element.img_path;
    return(
        <section className="section">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-md-4 mr-auto">
                    <h2 className="mb-4">{name}</h2>
                    <p className="mb-4">{description}</p>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                    <img src={ process.env.PUBLIC_URL + img_path } alt="" className="img-fluid" />
                </div>
                </div>
            </div>
        </section>
    );
}

class Products extends React.Component {

    render() {

        const productsList = this.props.products;

        const element = productsList.map((product) => {

            if (productsList.indexOf(product)%2 === 0) {
                return(
                    <ElementLeft key={product.name} element={product} />
                );
            } else {
                return(
                    <ElementRight key={product.name} element={product} />  
                );
            }
        });

        return(
            <div id="products">{element}</div>
        );
    }

}

class AboutUs extends React.Component {

    render() {

        const backgroundImgPath = process.env.PUBLIC_URL + "/assets/img/div_bg.svg";

        return(

            <div className="main" id="about-us" style={{backgroundImage: 'url(' + backgroundImgPath + ')' }}>
                <section className="section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mb-4" data-aos="fade-right">{this.props.title}</h2>
                                <p className="text-black" data-aos="fade-right" data-aos-delay="100">{this.props.description}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}

class Footer extends React.Component {

    render() {
        return(
            <footer className="footer" role="contentinfo">
            <div className="container">
                <div className="row text-center">
                <div className="col-md-6 mb-4 mb-md-0">
                    <h3>Quinch</h3>
                    <p>
                    IoT and AI company focused at making people's lives smarter and connected. We are ushering the age of smart living 
                    with our innovative technology that gives your life, state of the art security and convenience.</p>
                    <p className="social">
                    <a className="m-2" href="https://www.facebook.com/QuinchSystems"><span className="icofont-facebook" /></a>
                    <a className="m-2" href="https://www.instagram.com/quinchsystems/"><span className="icofont-instagram" /></a>
                    <a className="m-2" href="https://www.linkedin.com/company/quinch/"><span className="icofont-linkedin" /></a>
                    </p>
                </div>
                <div className="col-md-6 ml-auto">
                    <div className="row site-section pt-0">
                    <div className="col-md-12 mb-4 mb-md-0 text-center">
                        <p className="text-black text-uppercase"><strong>Important Links</strong></p>
                        <p><a className="text-info" href={process.env.PUBLIC_URL + "/terms-of-service"}>Terms of Service</a></p>
                        <p><a className="text-info" href={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</a></p>
                        <p><a className="text-info" href={process.env.PUBLIC_URL + "/refund-policy"}>Refund and Cancellation Policy</a></p>
                    </div>
                    </div>
                </div>
                </div>
                <div className="row justify-content-center text-center">
                <div className="col-md-7">
                    <p className="copyright">© Copyright Quinch Systems Pvt. Ltd., 2020</p>
                </div>
                </div>
            </div>
            </footer>
        );
    }

}

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { contact_fields : {}, responseSubmitted : "none" };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let contact_fields = this.state.contact_fields;

        contact_fields[event.target.name] = event.target.value;
        this.setState({"contact_fields" : contact_fields});
    }
    
    handleSubmit(event) {

        event.preventDefault();

        let fields = this.state.contact_fields;
        let params = new URLSearchParams();

        for (const key in fields) {
            params.append(key, fields[key]);
        }

        axios.post('/contact/index.php', params)
        .then((response) => {  
            let responseSubmitted = (response.data.success) ? "success" : "fail";
            this.setState({ responseSubmitted : responseSubmitted });

            setTimeout(() => {
                this.setState({ responseSubmitted : "none"});
            }, 7000);
        })
        .catch((error) => {
            console.log(error.toString());
        });

    }

    createInput(input) {

        let inputElement = null;

        if (input.type === "textarea") {
            inputElement =
            <textarea 
                className = "form-control mb-2" 
                type = {input.type}
                name = {input.name}
                id = {input.id}
                placeholder = {input.hint}
                cols = {input.cols}
                rows = {input.rows}
                onChange = {this.handleChange}
                required = {input.required}/>; 
        } else if (input.type === "submit") {
            inputElement = 
            <input 
                className = "btn btn-primary d-block"
                style={{margin:'0 auto'}}
                type = {input.type}
                name = {input.name}
                id = {input.id}
                required = {input.required}/>;
        } else {
            inputElement = 
            <input 
                className = "form-control mb-2" 
                type = {input.type}
                name = {input.name}
                id = {input.id}
                placeholder = {input.hint}
                onChange = {this.handleChange}
                required = {input.required}/>;
        }
        return(inputElement);
    }

    render() {

        const fields = this.props.fields;

        let form = fields.map(field => {

            const animDelayFactor = (fields.length >= 12) ? 50:100;
            const animDelay = fields.indexOf(field) * animDelayFactor;

            if (Array.isArray(field)) {
                const colSize = parseInt(12/field.length);
                return(
                    field.map(f => {

                        const required = f.required ? <span style={{color:'red'}}> *</span>:null;
                            
                        return (<div key={f.id} className={ 'col-md-' + colSize } data-aos="fade-up" data-aos-delay={animDelay}>
                                    <label className="text-black" htmlFor={f.name}>{f.label}{required}</label>
                                    {this.createInput(f)}
                                </div>);

                    })
                );
            } else {

                const required = field.required ? <span style={{color:'red'}}> *</span>:null;

                return (<div key={field.id} className="col-md-12" data-aos="fade-up" data-aos-delay={animDelay}>
                            <label className="text-black" htmlFor={field.name}>{field.label}{required}</label>
                            {this.createInput(field)}
                        </div>);
            }
        });

        const heading = <h2 className="section-heading">Get in touch</h2>;
        const formElement = <form className="form-row m-3" onSubmit={this.handleSubmit}>{ form }</form>

        return(

            <div className="main" id="contact">
                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center text-center mb-5">
                            <div className="col-md-6" data-aos="fade-up">
                                {(() => {
                                    if (this.state.responseSubmitted !== "fail" && this.state.responseSubmitted !== "success") {
                                        return heading;
                                    }
                                })()}   
                            </div>
                        </div>
                        <div className="row align-items-center">
                            {(() => {
                                if (this.state.responseSubmitted === "fail") {
                                    return(
                                        <div className="text-danger text-center w-100">
                                            <i className="las la-times-circle" style={{ fontSize: '64px' }}></i>
                                            <h4>Failed to send message! Please try again later.</h4>
                                        </div>
                                    );
                                } else if (this.state.responseSubmitted === "success") {
                                    return(
                                        <div className="text-success text-center w-100">
                                            <i className="las la-check-circle" style={{ fontSize: '64px' }}></i>
                                            <h4>Message sent successfully!</h4>
                                        </div>
                                    );
                                } else {
                                    return formElement;
                                }
                            })()}
                        </div>
                    </div>
                </section>
            </div>

        );
    }

}

class Main extends React.Component {

    render() {

        document.getElementById('loading').setAttribute('style', 'display:none;');

        const labels = ["Home", "Services", "Products", "About Us", "Contact"];

        const title = "We love solving problems!";
        const subtitle = "Cutting-Edge AI, IoT & Computer Vision innovations for your industrial and business challenges";

        const services = [
            {
                name: "IoT Solutions",
                description: "IoT-based edge devices that help monitor crucial aspects of your business",
                icon: "la-microchip"
            },
            {
                name: "AI-based Solutions",
                description: "Car Detection, Number Plate Recognition, Face Recognition, Perimeter Breach and a lot more using the power of AI",
                icon: "la-brain"
            },
            {
                name: "Computer Vision",
                description: "Bleeding-edge automation and other innovations that truly unleash the power of Computer Vision",
                icon: "la-dot-circle"
            },
            {
                name: "Custom Solutions",
                description: "Need a something a little more tailored to fit your business? We do that too!",
                icon: "la-tools"
            }
        ];

        const products = [
            {
                name: "QuiVision",
                description: "QuiVision is a platform that enables any IP based CCTV camera to be made into an AI enabled Smart Camera with a capability of sending Real-Time Events based Alerts thereby reducing the dependency on the human based surveillance systems.",
                img_path: "/assets/img/quivision.webp"
            },
            {
                name: "QuiDCS",
                description: "QuiDCS is an Integrated Infrastructure, Productivity and Security Management System that helps to monitor manufacturing, infrastructure and business asset in a fluid and centralised manner.",
                img_path: "/assets/img/quidcs.webp"
            },
            {
                name: "QuiCheck Pro",
                description: "QuiCheck Pro is an AI-enabled Computer Vision based Industrial and Measurement and Quality Monitoring platform which  uses AI based Image Analysis Technique to determine the quality parameters of Manufacturing Process Outputs.",
                img_path: "/assets/img/quicheck-pro.webp"
            },
        ];

        const aboutUsDescription = "Quinch is an AI and IoT Solutions Company helping businesses solve real life business process or industrial challenges with technology. Our products help organizations make business decisions based on the real-time data analytics available from their business premises and manufacturing units and also help there existing processes better with the use of technology. Businesses fail to understand the true condition and factors affecting them due to poor Data Collection and Analysis Practices and we help them Research, Develop and Deploy customized solutions for their needs. Our flagship platforms QuiVision, QuiDCS, and QuiCheck Pro help companies manage various aspects of their business processes. Thus, with the use of cutting edge AI and IoT techniques we help businesses realize the true potential inherent in them and help them deploy smart solutions that would solve their problems.";

        const contactFields = [
            {
                name: "name",
                type: "text",
                id: "name",
                hint: "Name...",
                label: "Name",
                required: true
            },
            [
                {
                    name: "email",
                    type: "email",
                    id: "email",
                    hint: "abc@example.com",
                    label: "Email",
                    required: true
                },
                {
                    name: "phone",
                    type: "tel",
                    id: "phone",
                    hint: "Phone Number with Country Code",
                    label: "Phone",
                    required: false
                }
            ],
            {
                name: "subject",
                type: "text",
                id: "subject",
                hint: "Subject...",
                label: "Subject",
                required: true
            },
            {
                name: "message",
                type: "textarea",
                id: "message",
                hint: "Write your message here...",
                label: "Message",
                required: true,
                cols: 30,
                rows: 10
            },
            {
                name: "submit",
                type: "submit",
                id: "submit"
            }
        ];

        return(
            <div>
                <NavBar navBarLables={labels} />
                <Banner title={title} subtitle={subtitle} />
                <div className="main" id="services">
                    <Services services={services} />
                    <Products products={products} />
                </div>
                <AboutUs title="About Us" description={aboutUsDescription} />
                <ContactForm fields={contactFields} />
                <Footer/>
            </div>
        );
    }
    
}

export default Main;